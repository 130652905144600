
import React, { Component } from 'react';
import Order from '../../../components/Order/Order';
import axios from './../../../axios-orders';
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';
import * as orderActions from './../../../store/actions/order';
import { connect } from 'react-redux';
import Spinner from '../../../components/UI/Spinner/Spinner';

class Orders extends Component {

    componentWillMount() {
        console.log('Orders componentWillMount');
        return true;
    }

    componentDidMount() {
        console.log('Orders componentDidMount');
        this.props.onFetchOrders();
    }

    render() {
        let orders = <Spinner />;

        if(!this.props.loading) {

            orders = (
                <div>
                    {this.props.orders.map(order => (
                        <Order key={order.id} ingredients={order.ingredients} price={order.price}/>
                    ))}
                </div>
            );
        }

        return orders;
    }

}

const mapStateToProps = (state) => {
    return {
        orders: state.order.orders,
        loading: state.order.loading
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchOrders: () => dispatch(orderActions.fetchOrders())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Orders, axios));
